export enum CourseOrderStatus {
  DraftValue = 1,
  ValidatedValue = 2,
  QueueingValue = 3,
  QueuedValue = 4,
  SendingValue = 5,
  SendtValue = 6,
  FailedSendingValue = 7,
  FailedOrderValue = 8,
  CanceledValue = 9,
  FailedQueueingValue = 10,
  DraftHistorialValue = 12,
  QueueingHistorialValue = 13,
  QueuedHistorialValue = 14,
  SendingHistorialValue = 15,
  SentHistorialValue = 16,
  FailedSendingHistorialValue = 17,
  FailedOrderHistorialValue = 18,
  FailedQueueingHistorialValue = 20,
}
