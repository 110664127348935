import { TravelAndExpenseStatus } from "../enums/courseRefund.enum";

export const courseParticipantExpenseStatusNorwegian = {
  [TravelAndExpenseStatus.Draft]: "Utkast",
  [TravelAndExpenseStatus.ToApproval]: "Til godkjenning",
  [TravelAndExpenseStatus.Approved]: "Godkjent",
  [TravelAndExpenseStatus.Rejected]: "Avvist",
  [TravelAndExpenseStatus.OrderCreated]: "Ordre opprettet",
  [TravelAndExpenseStatus.DraftHistorical]: "Utkast historisk",
  [TravelAndExpenseStatus.ToApprovalHistorical]: "Til godkjenning historisk",
  [TravelAndExpenseStatus.ApprovedHistorical]: "Godkjent historisk",
  [TravelAndExpenseStatus.RejectedHistorical]: "Avvist historisk",
  [TravelAndExpenseStatus.OrderCreatedHistorical]: "Ordre opprettet historisk",
} as const;

export const getCourseParticipantExpenseStatusByStatus = (statusCode: TravelAndExpenseStatus) =>
  courseParticipantExpenseStatusNorwegian[statusCode];
