import { OrderWithUUID, OrderLineWithUUID } from "./../interfaces/OrderWithUIID.interface";
import {
  ApiArticleDtoType,
  ApiGetOrderDto,
  ApiUpdateDraftDto,
  ApiOrderLineDto,
  ApiOrderParticipantDto,
  ApiOrderInvoiceRecipientDto,
  ApiGetCustomerDto,
} from "./../../api/generated/Api";
import { api } from "@/api/api";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { StoreState } from "@/store/store.state.interface";
import { Store } from "vuex";
import { CourseOrderButtonValues } from "../enums/courseOrderButtonValues.enum";
import { CourseOrderStatus } from "./../enums/courseOrderStatusEnum";

export const mapOrderToApiUpdateDraftDto = (order: ApiGetOrderDto | OrderWithUUID) => {
  if (!order.participant || !order.invoiceRecipient) {
    throw new Error("Participant and/or invoice recipient is missing");
  }

  const updateOrder: ApiUpdateDraftDto = {
    ...order,
    participant: {
      ...order.participant,
      userId: order.participant.userId ?? 0,
    },
    invoiceRecipient: {
      ...order.invoiceRecipient,
      userId: order.invoiceRecipient.userId ?? 0,
    },
  };
  return updateOrder;
};

export const inInvalidArticleInterval = (articleNo?: string | null) => {
  if (!articleNo) {
    return false;
  }
  return +articleNo >= 10000 && +articleNo <= 11000;
};

export const getArticleName = (articleNo: string, articles: ApiArticleDtoType[]) =>
  articles.find((currentArticle) => currentArticle.id === articleNo)?.name ?? `Ukjent (${articleNo})`;

export const getArticlesByArticleNos = (articleNos?: string[], articles?: ApiArticleDtoType[]) => {
  if (!articleNos || !articles) {
    return [];
  }

  const filteredArticles = articles.filter((item) => {
    if (!item.id) {
      return false;
    }
    for (const articleNo of articleNos) {
      if (articleNo === item.id) {
        return true;
      }
    }
  });

  return filteredArticles;
};

export const deleteOrder = async (store: Store<StoreState>, orderId: number) => {
  const deleteOrderResponse = await api.order.delete(orderId);
  if (deleteOrderResponse.status === 204) {
    openNotification(store, NotificationItemType.Success, "Ordre slettet");
  }
};

export const queueOrder = async (store: Store<StoreState>, orderId: number) => {
  const queueResponse = await api.order.queue(orderId);
  if (queueResponse.status === 204) {
    openNotification(store, NotificationItemType.Success, "Ordre lagt i kø");
  }
};

export const calculateTotalSum = (orderLine: ApiOrderLineDto | OrderLineWithUUID) =>
  orderLine.amount * orderLine.quantity * ((100 - orderLine.discount) / 100);

export const checkOrderActionButtonAvailable = (
  statusCode: CourseOrderStatus,
  requiredValue: CourseOrderButtonValues
) => AvailableButtonsByStatusCode[statusCode].includes(requiredValue) || "";

export const AvailableButtonsByStatusCode: Record<CourseOrderStatus, readonly CourseOrderButtonValues[]> = {
  [CourseOrderStatus.DraftValue]: [
    CourseOrderButtonValues.ValidateAvailable,
    CourseOrderButtonValues.EditAvailable,
    CourseOrderButtonValues.DeleteAvailable,
  ],
  [CourseOrderStatus.ValidatedValue]: [
    CourseOrderButtonValues.SendAvailable,
    CourseOrderButtonValues.DeleteAvailable,
    CourseOrderButtonValues.CancelAvailable,
  ],
  [CourseOrderStatus.SendingValue]: [CourseOrderButtonValues.ContraAvailable],
  [CourseOrderStatus.SendtValue]: [CourseOrderButtonValues.ContraAvailable],
  [CourseOrderStatus.QueueingValue]: [CourseOrderButtonValues.CancelAvailable],
  [CourseOrderStatus.QueuedValue]: [CourseOrderButtonValues.CancelAvailable],
  [CourseOrderStatus.FailedSendingValue]: [
    CourseOrderButtonValues.CancelAvailable,
    CourseOrderButtonValues.DeleteAvailable,
    CourseOrderButtonValues.QueueAvailable,
  ],
  [CourseOrderStatus.FailedQueueingValue]: [
    CourseOrderButtonValues.QueueAvailable,
    CourseOrderButtonValues.DeleteAvailable,
    CourseOrderButtonValues.CancelAvailable,
  ],
  [CourseOrderStatus.FailedOrderValue]: [
    CourseOrderButtonValues.QueueAvailable,
    CourseOrderButtonValues.DeleteAvailable,
    CourseOrderButtonValues.CancelAvailable,
  ],
  [CourseOrderStatus.CanceledValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.DraftHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.QueueingHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.QueuedHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.SendingHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.SentHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.FailedSendingHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.FailedOrderHistorialValue]: [CourseOrderButtonValues.Other],
  [CourseOrderStatus.FailedQueueingHistorialValue]: [CourseOrderButtonValues.Other],
} as const;

export interface OrderCustomer extends ApiOrderParticipantDto, ApiOrderInvoiceRecipientDto {
  birthDate?: string;
}

export const reduceToOrderCustomer = (orderCustomers?: ApiGetCustomerDto[]): OrderCustomer[] => {
  if (!orderCustomers) {
    return [];
  }
  return orderCustomers.reduce<OrderCustomer[]>((previous, current: ApiGetCustomerDto) => {
    const currentUser = {
      customerNo: current.id,
      name: current.customer.name ?? `${current.customer.firstName} ${current.customer.lastName}`,
      address: current.postAddress1,
      postNo: current.postZip ?? "",
      postArea: current.postCity ?? "",
      email: current.email ?? "",
      telephone: current.mobileNumber ?? current.phoneNumber,
      userId: current.id,
      orgNo: current.customer.orgNumber,
      birthDate: current.customer.birthDate,
    };
    return [...previous, currentUser];
  }, []);
};

export const labelMap = {
  customerNo: "ID",
  name: "Navn",
  orgNo: "Organisasjonsnummer",
  email: "E-post",
  telephone: "Telefonnummer",
  address: "Adresse",
  postNo: "Postnummer",
  postArea: "Poststed",
  isInternal: "Internfakturering",
  customerOrderNo: "Bestillingsnummer", // Innkjøpsordre nummer, PO nummer, Bestillingsnummer
  theirRef: "Referanse",
  contraEntryOrderId: "Kreditordre til",
  contraEntryOrdersId: "Kreditordre fra",
  paymentType: "Avdragstype",
  noOfPayments: "Antall betalinger",
  paymentInverval: "Avdrags intervall (mnd)",
  singleDeduction: "Samlet betaling",
  severalDeductions: "Flere avdrag",
  id: "Ordre Id",
  orderNo: "Ordrenummer",
  courseStartDate: "Startdato for kurs",
  courseEndDate: "Sluttdato for kurs",
  status: "Ordrestatus",
};

export const mapToOrderParticipantAndInvoiceRecipient = (
  orderCustomer: OrderCustomer
): ApiOrderParticipantDto | ApiOrderInvoiceRecipientDto => ({
  customerNo: orderCustomer.customerNo,
  name: orderCustomer.name,
  address: orderCustomer.address,
  postNo: orderCustomer.postNo,
  postArea: orderCustomer.postArea,
  email: orderCustomer.email,
  telephone: orderCustomer.telephone,
  userId: orderCustomer.userId,
  orgNo: orderCustomer.orgNo,
});
