
import { api } from "@/api/api";
import { ApiGetCourseParticipantTravelAndExpenseListDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { getCourseParticipantExpenseStatusByStatus } from "@/shared/helpers/courseParticipantExpeseHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { TravelAndExpenseStatus } from "@/shared/enums/courseRefund.enum";
import { StoreState } from "@/store/store.state.interface";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";

export default defineComponent({
  name: "CourseExpensesPage",
  components: { BaseTableFiltered, BaseLayout },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore<StoreState>();
    const course = computed(() => store.state.courses.course);
    const search = ref("");
    const courseExpenses = ref<ApiGetCourseParticipantTravelAndExpenseListDto[]>([]);
    const courseId = ref(+route.params.id || 0);

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await loadExpenses();
      });
    });

    const displayExpenseEntry = (id: string) => {
      router.push({
        name: SingleCourseRouteNames.CourseExpenseDetails,
        params: {
          id: route.params.id,
          expenseId: id,
        },
      });
    };

    const loadExpenses = async () => {
      courseExpenses.value = (
        await api.travelAndExpense.getCourseParticipantTravelAndExpenseList({
          CourseId: courseId.value,
          Statuses: [
            TravelAndExpenseStatus.Draft,
            TravelAndExpenseStatus.ToApproval,
            TravelAndExpenseStatus.Approved,
            TravelAndExpenseStatus.Rejected,
            TravelAndExpenseStatus.OrderCreated,
            TravelAndExpenseStatus.DraftHistorical,
            TravelAndExpenseStatus.ToApprovalHistorical,
            TravelAndExpenseStatus.ApprovedHistorical,
            TravelAndExpenseStatus.RejectedHistorical,
            TravelAndExpenseStatus.OrderCreatedHistorical,
          ],
        })
      ).data;
    };

    return {
      course,
      headers,
      search,
      courseExpenses,
      formatCurrency,
      formatLocalizedDate,
      displayExpenseEntry,
      getCourseParticipantExpenseStatusByStatus,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
    };
  },
});

const headers = [
  { text: "Refusjonskravnummer", value: "id" },
  { text: "Handlinger", value: "actions" },
  { text: "Dato", value: "date" },
  { text: "Fullt navn", value: "userFullName" },
  { text: "Kommentar", value: "description" },
  { text: "Sum", value: "totalAmount" },
  { text: "Status", value: "status" },
];
